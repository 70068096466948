<template>
  <house-profile-component />
</template>

<script>
import HouseProfileComponent from "@/components/houses/HouseProfileComponent";

export default {
  name: "HouseProfile",
  title: "Perfil del Hostal | Baja California Health Tourism",
  components: { HouseProfileComponent },
};
</script>

<style scoped></style>
